import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import 'mapbox-gl/dist/mapbox-gl.css'; // Импорт стиля Mapbox GL JS
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const mapboxAccessToken = 'pk.eyJ1IjoibWFwMjN0cmF2ZWwiLCJhIjoiY20xMDV4eGJ5MGVhejJrc2dzOXUxZGM5bCJ9.AZdS5yS841WJ0uUbD6kE0g';
const mapboxStyleId = 'mapbox://styles/map23travel/cm106d9a801a601qo19ehandv'; // Ваш ID стиля

mapboxgl.accessToken = mapboxAccessToken;

const map = new mapboxgl.Map({
    container: 'map',
    style: mapboxStyleId,
    center: [98.3923, 7.8804], // Пхукет
    zoom: 15
});

// const geojson = {
//     'type': 'FeatureCollection',
//     'features': [
//         {
//             'type': 'Feature',
//             'properties': {
//                 'message': 'Foo',
//                 'imageId': 1011,
//                 'iconSize': [60, 60]
//             },
//             'geometry': {
//                 'type': 'Point',
//                 'coordinates': [100.3923, 8.8804]
//             }
//         },
//         {
//             'type': 'Feature',
//             'properties': {
//                 'message': 'Bar',
//                 'imageId': 870,
//                 'iconSize': [50, 50]
//             },
//             'geometry': {
//                 'type': 'Point',
//                 'coordinates': [98.3923, 10.8804]
//             }
//         },
//     ]
// };

map.addControl(new mapboxgl.NavigationControl());

// Инициализация Mapbox Draw
const draw = new MapboxDraw({
    displayControlsDefault: false,
    controls: {
        point: true,
        line_string: true,
        polygon: true,
        trash: true
    }
});

map.addControl(draw);

map.on('load', function () {
    // ALL YOUR APPLICATION CODE
});


map.on('load', function () {
    // Загрузка первого маркера
    map.loadImage(
        'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        function (error, image1) {
            if (error) {
                console.error('Ошибка загрузки первого маркера:', error);
                throw error;
            }
            map.addImage('custom-marker', image1);

            // Загрузка второго маркера
            map.loadImage(
                'https://i.imgur.com/kiw0dR2.png',
                function (error, image2) {
                    if (error) {
                        console.error('Ошибка загрузки второго маркера:', error);
                        throw error;
                    }
                    map.addImage('second-marker', image2);

                    map.loadImage(
                        'https://i.imgur.com/BMzCtBO.png',
                        function (error, image3) {
                            if (error) {
                                console.error('Ошибка загрузки первого маркера:', error);
                                throw error;
                            }

                            map.addImage('third-marker', image3,
                                {pixelRatio: 10}
                            );

                            // Добавляем источник
                            map.addSource('places', {
                                'type': 'geojson',
                                'data': {
                                    'type': 'FeatureCollection',
                                    'features': [
                                        {
                                            'type': 'Feature',
                                            'properties': {
                                                'id': 1,
                                                'description':
                                                    '<strong>Make it Mount Pleasant</strong><p>Make it Mount Pleasant is a handmade and vintage market and afternoon of live entertainment and kids activities. 12:00-6:00 p.m.</p>',
                                                'marker-type': 'custom-marker',  // Указываем тип маркера
                                                'image': 'https://i.imgur.com/BMzCtBO.png',
                                            },
                                            'geometry': {
                                                'type': 'Point',
                                                'coordinates': [98.3923, 7.8804]
                                            }
                                        },
                                        {
                                            'type': 'Feature',
                                            'properties': {
                                                'id': 2,
                                                'description':
                                                    '<strong>Second Location</strong><p>This is the second location with a different marker.</p>',
                                                'marker-type': 'second-marker',  // Указываем тип второго маркера
                                                'image': 'https://i.imgur.com/BMzCtBO.png',
                                            },
                                            'geometry': {
                                                'type': 'Point',
                                                'coordinates': [98.398530, 7.890309]
                                            }
                                        },
                                        {
                                            'type': 'Feature',
                                            'properties': {
                                                'id': 3,
                                                'description':
                                                    '<strong>Глаз Рубин</strong><p>Ты можешь смотреть</p>',
                                                'marker-type': 'third-marker',
                                                'image': 'https://i.imgur.com/BMzCtBO.png',
                                            },
                                            'geometry': {
                                                'type': 'Point',
                                                'coordinates': [98.398530, 7.87]
                                            }
                                        }
                                    ]
                                }
                            });

                            // Добавляем слой с использованием разного маркера для каждой точки
                            map.addLayer({
                                'id': 'places',
                                'type': 'symbol',
                                'source': 'places',
                                'layout': {
                                    'icon-image': ['get', 'marker-type'],  // Берем тип маркера из properties
                                    'icon-allow-overlap': true
                                }
                            });
                        }
                    );
                }
            );

            // Создаем popup, но не добавляем его сразу на карту
            var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
            });

            map.on('mouseenter', 'places', function (e) {
                // Изменяем курсор при наведении
                map.getCanvas().style.cursor = 'pointer';

                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                var image = e.features[0].properties.image;

                // Поправляем координаты, если карта прокручена
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Создаем HTML контент для попапа
                const popupContent = `
                    <div>
                        <h3>${description}</h3>
                        <img src="${image}" alt="Image" style="width: 150px; height: auto;" />
                    </div>
                `;

                // Показываем popup
                popup.setLngLat(coordinates).setHTML(popupContent).addTo(map);
            });

            map.on('mouseleave', 'places', function () {
                map.getCanvas().style.cursor = '';
                // TODO мб убрать хз пока
                popup.remove();
            });

            map.on('click', 'places', function (e) {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                var imageUrl = e.features[0].properties.image; // URL изображения
                var featureId = e.features[0].properties.id; // Используем id из properties

                // Поправляем координаты, если карта прокручена
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                // Создаем форму редактирования с текстовым полем и кнопкой "Сохранить"
                var editForm = `
                    <div>
                        <textarea id="marker-description" style="width: 100%; height: 100px;">
                            ${description}
                        </textarea>
                        <br>
                        <input type="text" id="marker-image" style="width: 100%;" 
                          value="${imageUrl}" placeholder="Введите URL изображения">
                        <br>
                        <button id="save-description">Сохранить</button>
                    </div>
                `;

                // Создаем popup с формой редактирования
                var popup = new mapboxgl.Popup({
                    closeButton: true,
                    closeOnClick: false
                })
                    .setLngLat(coordinates)
                    .setHTML(editForm)
                    .addTo(map);

                // Добавляем обработчик для кнопки "Сохранить"
                document.getElementById('save-description').addEventListener('click', function () {
                    try {

                        var newDescription = document.getElementById('marker-description').value;
                        var newImageUrl = document.getElementById('marker-image').value;

                        // // Найдем соответствующий маркер по id и обновим его описание
                        // var features = map.querySourceFeatures(
                        //     'places', {filter: ['==', 'id', featureId]}
                        // );
                        // if (features.length > 0) {
                        //     var feature = features[0];
                        //     feature.properties.description = newDescription;
                        //
                        //     // Обновляем данные GeoJSON слоя
                        //     map.getSource('places').setData({
                        //         "type": "FeatureCollection",
                        //         "features": map.queryRenderedFeatures()
                        //     });
                        //
                        //     // Закрываем popup после сохранения
                        //     popup.remove();
                        //
                        //     // Логика для обновления данных на сервере может быть добавлена здесь
                        //     console.log('Новое описание сохранено:', newDescription);
                        // } else {
                        //     console.log('Фича не найдена для обновления.');
                        // }

                        // Получаем данные источника
                        var data = map.getSource('places')._data;

                        // Обновляем описание в данных источника
                        var featureUpdated = false;
                        for (var feature of data.features) {
                            if (feature.properties.id === featureId) {
                                feature.properties.description = newDescription;
                                feature.properties.image = newImageUrl;
                                featureUpdated = true;
                                break;
                            }
                        }

                        if (featureUpdated) {
                            // Обновляем данные источника
                            map.getSource('places').setData(data);
                        } else {
                            console.error('Фича не найдена для обновления.');
                        }

                        // Закрываем popup после сохранения
                        popup.remove();

                        console.log('Новое описание сохранено:', newDescription);
                        console.log('Новый URL изображения сохранен:', newImageUrl);
                    } catch (error) {
                        console.error('Ошибка при сохранении:', error);
                    }
                });
            });
        }
    );
});


// const marker1 = new mapboxgl.Marker()
//     .setLngLat([98.3923, 8.8804])
//     .addTo(map);
//
// // Create a default Marker, colored black, rotated 45 degrees.
// const marker2 = new mapboxgl.Marker({color: 'black', rotation: 45})
//     .setLngLat([99.3923, 7.8804])
//     .addTo(map);

// for (const marker of geojson.features) {
//     // Create a DOM element for each marker.
//     const el = document.createElement('div');
//     const width = marker.properties.iconSize[0];
//     const height = marker.properties.iconSize[1];
//     el.className = 'marker';
//     el.style.backgroundImage = `url(https://picsum.photos/id/${marker.properties.imageId}/${width}/${height})`;
//     el.style.width = `${width}px`;
//     el.style.height = `${height}px`;
//     el.style.backgroundSize = '100%';
//
//     el.addEventListener('click', () => {
//         window.alert(marker.properties.message);
//     });
//
//     // Add markers to the map.
//     new mapboxgl.Marker(el)
//         .setLngLat(marker.geometry.coordinates)
//         .addTo(map);
// }
